import React from 'react'
import styled from 'styled-components'

const CheckIcon = () => (
    <Svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.70711 10.7929C7.31658 10.4024 6.68342 10.4024 6.29289 10.7929C5.90237 11.1834 5.90237 11.8166 6.29289 12.2071L7.70711 10.7929ZM10 14.5L9.29289 15.2071C9.49156 15.4058 9.76464 15.5117 10.0453 15.499C10.326 15.4862 10.5884 15.356 10.7682 15.1402L10 14.5ZM15.7682 9.14018C16.1218 8.71591 16.0645 8.08534 15.6402 7.73178C15.2159 7.37821 14.5853 7.43554 14.2318 7.85982L15.7682 9.14018ZM6.29289 12.2071L9.29289 15.2071L10.7071 13.7929L7.70711 10.7929L6.29289 12.2071ZM10.7682 15.1402L15.7682 9.14018L14.2318 7.85982L9.23178 13.8598L10.7682 15.1402ZM20 11C20 15.9706 15.9706 20 11 20V22C17.0751 22 22 17.0751 22 11H20ZM11 20C6.02944 20 2 15.9706 2 11H0C0 17.0751 4.92487 22 11 22V20ZM2 11C2 6.02944 6.02944 2 11 2V0C4.92487 0 0 4.92487 0 11H2ZM11 2C15.9706 2 20 6.02944 20 11H22C22 4.92487 17.0751 0 11 0V2Z" fill="white" />
    </Svg>
)

export default CheckIcon

// CSS
const Svg = styled.svg`
  width: 25px;
  height: 25px;
  fill: #fff;
  justify-self: flex-end;
`
